import React from "react"
import NewLayout from "../components/new_layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { initializeHubspotForm } from "../utils/gatsby-helpers"

const Partners = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node

    initializeHubspotForm(page.form.portal_id, page.form.form_id, "#partners-form", page.form.region)

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg color-section green">
                    <div className="container" >
                        <div className="container__50">
                            <h1 className="merriweather">{page.title}</h1>
                            <div className="container__text">{page.subtitle}</div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage alt={page.header_image.alternativeText} image={getImage(page.header_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.info_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.info_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <button className="button button--xl" onClick={() => scrollTo("#form-container")}>{page.info_button}</button>
                    </div>
                </div>
                <div className="main__section main__section--50">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.connect_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.connect_description_one} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.connect_image.alternativeText} image={getImage(page.connect_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile">
                            <div className="tile__body">
                                <ReactMarkdown children={page.connect_description_two} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section">
                    <div className="container container--center">
                        <div className="grid-lg-2">
                            {page.types.map((type, index) => {
                                return <div className="image-group" key={index}>
                                    <div>
                                        <GatsbyImage alt={type.icon.alternativeText} image={getImage(type.icon.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <h4>{type.title}</h4>
                                        <ReactMarkdown children={type.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--50 highlight-section purple" id="form-container">
                    <div className="container">
                        <div className="container__50 text--center">
                            <div className="title-xl">
                                {page.form_title}
                            </div>
                        </div>
                        <div className="container__50">
                            <div className="tile tile--white">
                                <div id="partners-form" className="form-hb" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </NewLayout>
    )
}

export default Partners

export const partnersQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2022Partners ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    subtitle
                    header_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 750
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    header_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 750
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    info_title
                    info_description
                    info_button
                    connect_title
                    connect_description_one
                    connect_description_two
                    connect_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 500
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    connect_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 700
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    types {
                        order
                        title
                        description
                        icon {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 930
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    form_title
                    form {
                        region
                        portal_id
                        form_id
                    }
                }
            }
        }
    }
`
